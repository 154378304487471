import en from './en'
import de from './de'
import es from './es'
import kr from './kr'
import it from './it'
import fr from './fr'
import sr from './sr'
import tr from './tr'

export default {
  namespaced: true,
  modules: {
  	en
  },
  actions: {
  	registerLang ({}, lang) {
		if (lang === 'en') {
			this.registerModule(['lang', lang], en);
		}
		if (lang === 'de') {
			this.registerModule(['lang', lang], de);
		}
		if (lang === 'es') {
			this.registerModule(['lang', lang], es);
		}
		if (lang === 'kr') {
			this.registerModule(['lang', lang], kr);
		}
		if (lang === 'it') {
			this.registerModule(['lang', lang], it);
		}
		if (lang === 'fr') {
			this.registerModule(['lang', lang], fr);
		}
		if (lang === 'sr') {
			this.registerModule(['lang', lang], sr);
		}
		if (lang === 'tr') {
			this.registerModule(['lang', lang], tr);
		}
	}
  }
}
