import PullTo from 'vue-pull-to'
import {EventBus} from "../store/event-bus";
export default {
  data () {
    return {
      onTop: true
    }
  },
  components: {
    PullTo
  },
  computed: {
    topConfig () {
      return {
        pullText: '',
        triggerText: '',
        loadingText: '',
        doneText: '',
        failText: '',
        forceStop: false
      }
    },
    allowedPage() {
      return ['HomeUsername', 'HomeSerial', 'Tags'].includes(this.route.name)
    }
  },
  mounted () {
    if (this.route.name) this.initPoT()
    EventBus.$on('HomeEditing', (editing) => {
      this.forceStop = editing
      if (!editing) this.initPoT()
      else this.onTop = false
    })
  },
  methods: {
    initPoT () {
      this.onTop = this.allowedPage
      window.addEventListener('scroll', () => {
        this.onTop = window.pageYOffset === 0 && this.allowedPage && !this.forceStop
      })
    },
    refresh (loaded) {
      this.$router.push({ hash: 'refresh'})
      setTimeout(() => {
        loaded('done')
      }, 1500)
    }
  },
  watch: {
    'route.name'(nv) {
      if (nv) this.initPoT()
    }
  }
}
