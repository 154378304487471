export default {
  state: {
    english: "Englisch",
    spanish: "Spanisch",
    german: "Deutsch",
    korean: "Koreanisch",
    italian: "Italienisch",
    french: "Französisch",
    serbian: "Serbisch",
    turkish: "Türkisch",
    app_language: "Sprache",
    profile_preview: "Profil ansehen",
    edit_profile: "Profil bearbeiten",
    login: "Einloggen",

    referral_reach:
      "Geben Sie uns weitere Informationen über Sie und Ihre Reichweite.",
    referral_apply_placeholder: "Wie würden Sie Tapni promoten?",
    referral_apply_note:
      "Bitte vervollständigen Sie Ihr Profil, <br>\n" +
      "\t\t damit wir wissen, wer Sie sind.\n" +
      "\t\t  <br><br>Seien Sie exklusiv.\n" +
      "\t\t  <br>Nicht jeder wird akzeptiert.😉",
    referral_apply_now: "Jetzt bewerben",
    referral_apply_thanks:
      "Danke für die Bewerbung. Wir prüfen Ihre Bewerbung in Kürze!",
    connect: "Verbinden",
    exchange_contact: "Kontakt austauschen",
    add_details: "Mehr hinzufügen",
    show_details: "Mehr anzeigen",
    download_contact: "Kontakt herunterladen",
    stay_in_touch: "Bleiben Sie in Kontakt mit",
    no_thanks: "Nein Danke",
    connect_join: "Tapni beitreten",
    connect_with: "Verbinden mit",
    connect_required_fields: "Ein Kontaktfeld muss angegeben werden",
    name: "Name",
    first_name: "Vorname",
    last_name: "Nachname",
    email: "Email",
    phone: "Handynummer",
    phone_p: "Handy",
    company: "Unternehmen",
    address: "Anschrift",
    website: "Website",
    open: "Öffnen",
    delete: "Löschen",
    confirm: "Bestätigen",
    cancel: "Abbrechen",
    save: "Speichern",
    code: "Code",
    note: "Notiz",
    note_p: "Notiz hinzufügen",
    contact_card_icon: "Legen Sie Text unter dem Kontaktkartensymbol fest",
    contact_card_name: "Namen für die Kontaktkarte eingeben",
    contact_card_name_p: "Name der Kontaktkarte",
    contact_card_email: "Email für die Kontaktkarte eingeben",
    contact_card_email_p: "Kontaktkarten-Email",
    contact_card_phone: "Telefon für die Kontaktkarte eingeben",
    contact_card_phone_p: "Kontaktkarte Telefon",
    contact_card_website: "Webseite für die Kontaktkarte eingeben",
    contact_card_website_p: "Kontaktkarte Webseite",
    contact_card_company_name: "Firmennamen für die Kontaktkarte eingeben",
    contact_card_company_name_p: "Kontaktkarte Firmennamen",
    contact_card_job_title: "Berufsbezeichnung",
    contact_card_job_title_p: "Berufsbezeichnung eingeben",
    contact_card_company_website: "Website des Unternehmens",
    contact_card_company_website_p: "Unternehmenswebsite eingeben",
    contact_card_bio: "Bio für die Kontaktkarte eingeben",
    contact_card_bio_p: "Kontaktkarte bio",
    contact_card_home_address: "Privatadresse für die Kontaktkarte eingeben",
    contact_card_home_address_p: "Privatadresse der Kontaktkarte",
    contact_card_work_address: "Arbeitsadresse für die Kontaktkarte eingeben",
    contact_card_work_address_p: "Kontaktkarte Arbeitsadresse",
    contact_card_social:
      "Wählen Sie Social-Media-Links zu Ihrem Tapni-Profil aus <br>um sie in die Kontaktkarte aufzunehmen",
    contact_card_social_p:
      "Wählen Sie Social-Media-Links für die Kontaktkarte aus",

    link_text_p: "Link Text",
    link_icon: "Text unter dem Link setzen",

    direct_pick: "Direktlink auswählen",
    direct_p:
      "Leiten Sie Besucher auf diesen Link, <br> statt auf Ihr Tapni Profil um.",

    password_change: "Passwort ändern",
    password_repeat: "Wiederhole das Passwort",
    password_reset_t: "Passwort zurücksetzen",
    password_current: "Aktuelles Passwort eingeben",
    password_current_p: "Aktuelles Passwort",
    password_new: "Neues Passwort eingeben",
    password_new_p: "Neues Passwort",
    password_new_repeat: "Neues Passwort wiederholen",
    password_new_repeat_p: "Neues Passwort erneut eingeben",
    password_update: "Passwort aktualisieren",
    password_reset:
      "Wenn Sie Ihr aktuelles Passwort vergessen haben,<br>\n" +
      "\t\t  können Sie es zurücksetzen",
    by_clicking_here: "indem Sie hier klicken",
    password_8_chars: "Passwort muss mindestens 8 Zeichen enthalten",
    password_not_match: "Passwörter stimmen nicht überein",
    password_change_success: "Passwort erfolgreich geändert",

    or: "oder",
    profile: "Profil",
    profile_public: "Öffentliches Profil",
    profile_public_p: "Ihr Profil ist öffentlich zugänglich über: ",
    profile_active: "Aktives Profil",
    profile_active_p:
      "Wenn du dein Profil deaktivierst, hat niemand mehr Zugriff auf dein Profil und die verknüpften Tags funktionieren nicht mehr",
    profile_change_password: "Passwort ändern",
    profile_change_username: "Username ändern",
    profile_change_language: "Sprache ändern",
    profile_delete_account: "Konto löschen",
    profile_confirm_delete_account: "Konto löschen",
    profile_cancel_delete_account: "Abbrechen",
    profile_delete_account_success: "Das Konto wurde erfolgreich gelöscht",
    profile_remove_p:
      "Wenn Sie Ihr Konto entfernen möchten,<br>\n" +
      "\t\t    schreiben Sie uns bitte an",
    profile_contact_support:
      "Wenn Sie Ihr Konto löschen möchten,<br>\n" +
      "\t\t    schreiben Sie uns bitte an",
    profile_email_subject: "Tapni App Support",
    profile_remove_subject: "Antrag auf Entfernung des Kontos",
    profile_update_success: "Profil erfolgreich aktualisiert",
    profile_complete: "Profil vervollständigen",
    completed: "Vollendet",
    uncompleted: "Unvollendet",
    contact_us: "kontaktiere uns",
    contact_us_verify: "um Ihre Email-Adresse zu verifizieren.",
    verify_email: "Email Adresse bestätigen",
    verify_email_subject: "Email Verifizierung",
    verify_email_inbox: "Überprüfen Sie Inbox von",

    add_first_link: "Füge deinen ersten Link hinzu",
    add_first_link_p:
      "Tippen Sie hier, um Ihre Social-Media-Kanäle<br>\n" +
      "\t\t\t  oder Kontaktinformationen hinzuzufügen.",
    add_picture: "Profilbild hinzufügen",
    add_picture_p: "Tippen Sie hier, um Ihr Profilbild hochzuladen.",
    add_tag: "Aktiviere dein Tapni",
    add_tag_p: "Tippen Sie hier, um Ihr Tapni NFC Tag zu aktivieren.",
    completing_profile:
      "Probleme bei der Einrichtung Ihres Profils?<br>\n" +
      "\t\t    Schreiben Sie uns gerne an ",
    completing_profile_subject: "Profil vervollständigen",
    share_profile: "Profil teilen",
    share_profile_p:
      "Andere können diesen QR-Code <br> scannen, um Ihr Profil zu sehen",
    share_profile_change_username:
      "Wenn Sie Ihren Link ändern möchten, <br> <u>klicken Sie hier</u>, um Ihren Benutzernamen zu ändern.",
    scan_qr_code: "QR Code scannen",
    enter_activation_code: "Aktivierungscode eingeben",
    activate_tag: "Tag aktivieren",
    order_tapni:
      "Falls Sie Ihr Tapni noch nicht bestellt haben,<br>\n" +
      "          Sie können sich einen aussuchen ",
    activation_success: "Tag erfolgreich verbunden. <br> Das wars schon!🥳",
    activation_success_message:
      "Tag erfolgreich verbunden. <br> Das wars schon!🥳",
    activation_error:
      "Dies ist kein gültiges Tag. <br> Versuchen Sie es erneut.",
    activation_error_message:
      "Dies ist kein gültiges Tag. <br> Versuchen Sie es erneut.",
    activation_progress: "Registrierung des Tags läuft...",
    activation_invalid_code: "Ungültiger Aktivierungscode.",
    activation_qr_failed: "QR Code Scannen fehlgeschlagen",
    activation_qr_area: "Platzieren Sie einen QR-Code im Scanbereich",
    activation_code: "Aktivierungscode",
    invalid_code: "Ungültiger Code",
    total_taps: "Gesamtzahl der Taps",
    active: "Activ",
    tag_active_p:
      "Wenn deaktiviert, wird Ihr Profil nicht geöffnet, <br> wenn Sie aufs Handy anderer Person tippen.",
    tag_delete_confirm:
      "Speichern Sie den Aktivierungscode,<br> bevor Sie den Tag löschen.",

    upload_profile_picture: "Profilbild hochladen",
    upload_cover_picture: "Titelbild hochladen",
    upload_link_picture: "Link Bild hochladen",
    select_from_device: "Aus Gerät auswählen",
    select_from_mobile: "Wählen Sie ein Bild von Ihrem Mobilgerät aus",
    select_from_computer:
      "oder ziehen Sie es per Drag & Drop, wenn Sie einen Computer verwenden",
    crop: "Ausschneiden",
    crop_upload: "Ausschneiden and Hochladen",
    crop_image: "Bild beschneiden",
    drag_and_drop: "Dateien per Drag & Drop hierher ziehen",
    upload_success: "Medien erfolgreich hochgeladen",
    browser_not_supported: "Ihr Browser unterstützt nicht",
    change_username: "Benutzernamen ändern",
    change_username_success: "Benutzername erfolgreich geändert",
    update_username: "Benutzername aktualisieren",
    new_username: "Geben Sie Ihren neuen Benutzernamen ein",
    username_check: "Bitte korrekten Benutzernamen angeben",
    anonymous: "Anonym",
    connected: "Verbunden",
    exchanged: "Ausgetauscht",
    accept: "Akzeptieren",
    view: "Ansehen",
    join: "Beitreten",
    add_account: "Konto hinzufügen",
    complete_profile: "Profil vervollständigen",
    my_profile: "Mein Profil",
    my_qr: "Mein QR",
    my_connections: "Meine Kontakte",
    my_tags: "Meine Tags",
    buy_tapni: "Tapni holen",
    referral_program: "Referral Programm",
    device_compatibility: "Gerätekompatibilität",
    help: "Hilfe",
    settings: "Einstellungen",
    logout: "Ausloggen",
    version: "Version",
    configure: "Konfigurieren",
    unexpected_err:
      "Ein unerwarteter Fehler ist aufgetreten, bitte kontaktieren Sie uns unter support@tapni.co",
    success_login: "Erfolgreich mit einem anderen Konto angemeldet.",
    password_reset_success: "Erfolgreich mit einem anderen Konto angemeldet.",
    password_reseted_success:
      "Passwort erfolgreich geändert. Sie können sich jetzt einloggen.",
    success_remove_link: "Link erfolgreich gelöscht",
    profile_active_message: "Ihr Profil ist jetzt aktiv",
    profile_inactive_message: "Ihr Profil ist jetzt deaktiviert",
    profile_public_message: "Ihr Profil ist jetzt öffentlich",
    profile_private_message: "Ihr Profil ist jetzt privat",
    redirect_enabled: "Link zur automatischen Weiterleitung aktiviert",
    redirect_disabled: "Link zur automatischen Weiterleitung deaktiviert",
    tag_active: "Tag ist jetzt aktiv. Jetzt geht's los!",
    tag_inactive:
      "Tag ist jetzt deaktiviert. Ihre Besucher landen auf tapni.co",
    tag_removed: "Tag erfolgreich entfernt",
    connection_accepted: "Verbindungsanfrage akzeptiert.",
    not_found: "Nicht gefunden",
    not_found_p: "Es scheint, als wärst du verloren",
    go_back: "Geh zurück",
    referral_p1: "Verdienen Sie 20% Provision<br> auf Affiliate-Verkäufe💰",
    referral_p2: "Schenken Sie anderen 20% Rabatt<br> mit Ihrem Rabattcode 🎁",
    referral_p3: "Es ist Win-Win🤝",
    referral_p4:
      "Wenn Sie Ihre Daten ändern möchten,<br>\n" +
      "\t\t\t\t  oder Fragen haben,<br>\n" +
      "\t\t\t\t  schreiben Sie uns an",
    referral_p5: "Jetzt Geld verdienen!",
    referral_p66: "Teilen Sie Ihren Affiliate-Link und verdienen Sie Geld!",
    referral_p6:
      "Wenn Sie Blogger, Influencer oder Social Media-Enthusiast sind, passen Sie perfekt in unser Referral-Programm.\n" +
      "\t\t\t\tPromoten Sie Tapni und verdienen Sie 20% Provision auf Einkäufe, die mit Ihrem Rabattcode oder Empfehlungslink getätigt wurden.",
    referral_p7:
      "Nach der Registrierung für das Tapni Referral-Programm erhalten Sie einen persönlichen Empfehlungslink und einen Rabattcode.\n" +
      "\t\t\t\tTeilen Sie sie auf Ihrer Website und in sozialen Medien und verdienen Sie Geld, während wir ein großartiges Verbindungserlebnis bieten.",
    referral_p8: "Auszahlungshinweis",
    referral_p9:
      "Paypal (weltweit) und Banküberweisungen (EU) <br> Der Mindestauszahlungsbetrag beträgt 25€.",
    referral_p10: "Auszahlung anfordern",
    referral_p11: "Auszahlung angefordert",
    your_connections: "Ihre Kontakte",
    connections_p1:
      "Treten Sie mit Leuten in Kontakt, die Sie kennengelernt haben",
    connections_p2: "<br><br>Bisher keine Kontakte.<br> Let's get tappin'💥",
    connections_p3: "Keine Kontakten gefunden.",
    connection_success: "Verbindungsanfrage erfolgreich gesendet",
    exchange_success: "Erfolgreicher Austausch von Kontakten.",
    create_free_account:
      "Erstellen Sie ein kostenloses Tapni-Konto und halten Sie Ihre Kontakte auf dem Laufenden!",
    connection_edit: "Kontakt bearbeiten",
    connection_edit_success: "Kontakt erfolgreich aktualisiert",
    connection_delete_success: "Kontakt erfolgreich gelöscht",
    search: "Suche",
    devices_p1: "Liste der Gerätekompatibilität",
    devices_p2:
      "Tapni verwendet eine Technologie, die mit den meisten neueren iPhone- und Android-Geräten kompatibel ist.",
    devices_p3:
      "Bei einigen Android-Telefonen ist NFC möglicherweise deaktiviert. Wenn Ihr Tapni auf einem Android nicht funktioniert, suchen Sie in den Einstellungen nach NFC und stellen Sie sicher, dass es eingeschaltet ist.",
    devices_p4: "mit iOS 14 NFC-Widget im Kontrollzentrum",
    download_p1: "Tapni App herunterladen",
    download_p2: "Verfügbar für Apple- und Android-Geräte",
    user_not_found: "Benutzer nicht gefunden",
    user_not_found_p:
      "Wir konnten den Benutzer nicht in unserer Datenbank finden 🤷",
    taps: "tipps",
    private_profile: "Privates Profil",
    private_profile_p:
      "ist ein privates Profil. <br> Profil ist nur mit Tapni-Tags sichtbar ",
    profile_bio_p: "Schreib etwas über dich oder deine Marke",
    add_to_contacts: "Zu den Kontakten hinzufügen",
    drag_and_drop_links: "Links mit Drag & Drop sortieren",
    no_links: "Ihr Profil enthält keine Links.",
    add_link: "Hinzufügen",
    direct_link_p1: "Besucher werden automatisch weitergeleitet zu:",
    profile_not_active:
      "Ihr Profil ist derzeit nicht aktiv <br> und nur Sie können es sehen, wenn Sie eingeloggt sind.",
    profile_not_public:
      "Ihr Profil ist derzeit nicht öffentlich <br> und nur diejenigen, die auf Sie tippen, können es sehen.",
    activate_profile_now: "Aktiviere jetzt dein Profil",
    publish_profile_now: "Mach dein Profil jetzt öffentlich",
    link_add_p1: "Linktyp auswählen",
    link_add_not_found: "Keine Links gefunden 🤷",
    link_add_not_found_p2:
      "Wenn wir Ihre Lieblingsplattform verpasst haben, senden Sie uns bitte eine Email (<b>support@tapni.co</b>) oder eine DM in den sozialen Medien (<b>@tapni.co</b>).",
    link_add_not_found_p3:
      "Wir werden unser Bestes geben, um alle Plattformen zu unterstützen 😉",
    featured: "Populär",
    social_media: "Soziale Netzwerke",
    contact: "Kontakt",
    finance: "Finanzen",
    portfolio: "Portfolio",
    music: "Musik",
    business: "Business",
    fun: "Spaß",
    ecommerce: "Ecommerce",
    more: "Mehr",
    email_username: "Email / Benutzername",
    username: "Benutzername",
    password: "Passwort",
    sign_in: "Einloggen",
    sign_in_with: "Einloggen mit",
    sign_in_with_new: "Mit neuem Konto anmelden",
    sign_up_with: "Registrieren mit",
    verify_account: "Konto bestätigen",
    terms_by_signing_in: "Beim Einloggen akzeptieren Sie unsere",
    terms_by_registering: "Beim Registrieren akzeptieren Sie unsere",
    terms_by_submitting:
      "Mit dem Absenden dieses Formulars erklären Sie sich mit unseren Allgemeinen Geschäftsbedingungen einverstanden.",
    terms_of_service: "Nutzungsbedingungen",
    and: "und",
    refresh: "Aktualisieren",
    privacy_policy: "Datenschutzbestimmungen",
    create_account: "Konto erstellen",
    reset_password: "Passwort zurücksetzen",
    reset_password_p1:
      "Wenn Sie Ihr Passwort nicht zurücksetzen konnten,<br> kontaktieren Sie uns bitte unter ",
    reset_password_p2:
      "Wenn Sie Ihr Passwort nicht zurücksetzen konnten,<br> kontaktieren Sie uns bitte unter",
    create_new_account: "Neuen Account erstellen",
    maintenance_p1: "Wir werden bald zurück sein",
    maintenance_p2: "Entdecken Sie in der Zwischenzeit die Tapni-Kollektionen",
    maintenance_p3:
      "Tapni ist wegen einer geplanten Wartung nicht erreichbar <br> und wird voraussichtlich in wenigen Minuten wieder online sein.",
    offline_p1: "Keine Internetverbindung",
    offline_p2:
      "Überprüfen Sie Ihre Verbindung und aktualisieren Sie dann die Seitee",
    offline_p3_1: "Wenn Sie offline sind,",
    offline_p3_2: "können Sie Ihre Kontaktkarte mit diesem QR-Code freigeben",
    products_p1:
      "Beeindrucke jeden, den du triffst 😎 <br> Hol dir jetzt deinen 👇",
    cards: "Karten",
    stickers: "Stickers",
    keychains: "Schlüsselanhänger",
    explore_collection: "Produkte anschauen",
    register: "Registrieren",
    register_success: "Sie sind fast fertig. Bitte bestätige deine Email.",
    register_success_modal: "Erfolgreich mit einem anderen Konto angemeldet.",
    check_your_inbox: "Inbox überprüfen",
    tapni_tags: "Tapni Tags",
    tapni_tags_p1:
      "Konfigurieren Sie Tags, die mit Ihrem Profil verbunden sind",
    tapni_tags_p2:
      "Es sind keine Tags mit Ihrem Profil verknüpft. <br> Aktivieren Sie jetzt eines!",
    verify_account_p1:
      "Wenn Sie keinen Code erhalten haben, <br> überprüfen Sie Ihren Spam-Ordner oder<br> kontaktieren Sie uns unter",
    verify_account_success:
      "Konto erfolgreich verifiziert. Bitte ändern Sie jetzt Ihr Passwort password.",
    verify_account_success2:
      "Konto erfolgreich verifiziert. Jetzt geht's los!🥳",
    welcome: "Willkommen",
    welcome_p1: "in der Zukunft der Visitenkarten",
    how_it_works: "Wie funktioniert's",
    already_registered: "Bereits registriert",
    error: "Fehler",
    success: "Erfolg",
    copied: "Kopiert",
    connect_validate: "Ein Kontaktfeld muss angegeben werden",
    connect_validate_name: "Bitte geben Sie Ihren Namen ein",
    connect_validate_email: "Bitte geben Sie eine gültige E-Mail Adresse ein",
    link_validate: "Ungültige Linkdaten.",
    link_text_contact_card: "Kontaktkarte",
    link_text_custom_link: "Custom Link",
    link_text_menu: "Menü",
    link_text_phone: "Telefon",
    save_to_wallet: "In Apple Wallet speichern",
    card: "Karte",
    sticker: "Aufkleber",
    keychain: "Schlüsselbund",
    band: "Band",
    case: "Case",
    standee: "Aufsteller",
    decoded: "Decoded",
    wristbanditz: "Wristbanditz",
    update_p1: "Veraltete App Version erkannt",
    update_p2: "Aktualisieren",
    update_p3: "Bitte aktualisieren Sie die App auf die neueste Version",

    company_domain: "Ihre Unternehmensdomäne",
    search_company_domain: "Suchen Sie Ihre Firmendomain",
    company_not_found: "Unternehmen nicht gefunden",
    ik_company_domain: "Ich kenne die Firmendomain",
    idk_company_domain: "Ich kenne die Firmendomain nicht",
    continue: "Weiter",
    forbidden_rule:
      "Diese Option ist deaktiviert, wenden Sie sich an Ihren Firmenadministrator.",
    companyName: "Name des Unternehmens",
    createMarkerPlaceholder: "Erstellen oder Auswählen einer Markierung",
    clickToCreateMarker: "Klicken Sie hier, um eine Markierung zu erstellen",
    pleaseInviteUser:
      "Bitte laden Sie den Benutzer ein, Tapni beizutreten, um die Daten mit Ihnen zu synchronisieren.",
    connectionSynchronized:
      "Die Verbindungsdaten werden automatisch synchronisiert.",
    viewProfile: "Profil ansehen",
    createConnectionHeader: "Neue Verbindung hinzufügen",
    createConnectionButton: "Hinzufügen",
    createConnectionSuccess: "Die Verbindung wurde hergestellt.",
    allow_company_management: "Ermöglichen Sie der Unternehmensleitung",
    company_join_request:
      "bittet darum, Ihr Profil zu verwalten und es mit seinem Enterprise-Konto zu verknüpfen",
    account_ownership_transfer:
      "Das Eigentum an Ihrem Konto geht auf das Unternehmen über, wenn Sie die Anfrage annehmen. Das Unternehmen hat dann die Möglichkeit, Ihr Profil zu ändern, Links zu bearbeiten und hinzuzufügen, Ihre Verbindungen einzusehen, Ihr Passwort zu ändern, Ihr Konto zu löschen und vieles mehr. Erkundigen Sie sich bei Ihrem Unternehmen, bevor Sie die Anfrage annehmen.",
    request_expired:
      "Der Antrag ist abgelaufen. Bitten Sie Ihr Unternehmen, einen neuen Antrag zu stellen.",
    request_expiration_desc:
      "Aus Sicherheitsgründen wird der Antrag nur 14 Tage nach seiner Absendung bearbeitet.",
    invitation_link_not_valid: "Der Einladungslink ist nicht gültig!",
    invitation_link_not_valid2:
      "Klicken Sie erneut auf die Schaltfläche in der Einladungs-E-Mail oder kopieren Sie den Einladungslink.",
    accept_request: "Anfrage annehmen",
    close: "Schließen",
    request_accepted: "Die Anfrage wurde erfolgreich angenommen",
    companyEmail: "Unternehmen E-Mail",
    companyPhone: "Unternehmen Telefon",
    addBusinessInformations: "Geschäftsinformationen hinzufügen",
    resetFilter: "Zurücksetzen",
    selectMarkers: "Markierungen auswählen",
    filterConnectionsHeader: "Filter-Anschlüsse",
    selectConnectionType: "Verbindungstyp auswählen",
    all: "Alle",
    direct: "Direkt",
    form: "Formular",
    manually: "Manuell",
    scan: "Scannen",
    sortOptions: "Optionen sortieren",
    date: "Erstellungsdatum",
    fullName: "Vollständiger Name",
    descending: "Absteigend",
    ascending: "Aufsteigend",
    selectDateRange: "Datumsbereich auswählen",
    follow_up_reminder: "Erinnerungsschreiben",
    now: "Jetzt",
    one_hour: "1 Stunde",
    one_day: "1 Tag",
    three_days: "3 Tage",
    one_week: "1 Woche",
    two_weeks: "2 Wochen",
    one_month: "1 Monat",
    custom: "Zeit wählen",
    set_reminder: "Erinnerung einstellen",
    select_date_time: "Datum und Uhrzeit auswählen",
    enter_reminder_text: "Erinnerungstext eingeben",
    reminder_created: "Erinnerung erfolgreich erstellt",
    connectionNameLabel: "Name der Verbindung eingeben",
    connectionEmailLabel: "Private E-Mail-Adresse eingeben",
    privatePhoneLabel: "Private Rufnummer eingeben",
    companyNameLabel: "Firmenname eingeben",
    businessEmail: "Geschäftliche E-Mail Adresse",
    businessEmailLabel: "Geschäfts-E-Mail-Adresse eingeben",
    businessPhone: "Telefonnummer des Unternehmens",
    businessPhoneLabel: "Geschäftliche Rufnummer eingeben",
    noteLabel: "Notiz eingeben",
    markersLabel: "Verbindungsmarkierungen hinzufügen",
    showScan: "Scan anzeigen",
    hideScan: "Scan ausblenden",
    create_connection: "Verbindungen hinzufügen",
    create_manually: "Manuell hinzufügen",
    create_using_scanner: "Hinzufügen mit Kartenscanner",
    companyTitle: "Titel des Unternehmens",
    companyTitleLabel: "Titel des Unternehmens eingeben",
    allConnectionsLabel: "Alle Verbindungsarten",
    directConnectionsLabel: "Direkte In-App-Verbindung mit Tapni-Nutzern",
    formConnectionsLabel:
      "Über das Verbindungsformular ausgefüllte Verbindungen",
    manualConnectionsLabel: "Manuell erstellte Verbindungen",
    scanConnectionsLabel: "Kartenleser erstellt Verbindungen",
  },
};
