<template>
  <form
    @submit.prevent="save"
    class="menu-wrapper menu-light menu-modal activate-page"
    :class="{ 'active-menu': toggle }"
    style="height: auto; max-height: 90vh; margin-top: -172.5px"
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="
            width: 45%;
            display: block !important;
            margin: 0 auto !important;
          "
        />
      </i>
    </a>
    <!--
        <img :src="'https://cdn.tapni.co/icons/settings.png'" class="profile-img" :alt="lang[this.appLang].profile"
       -->
    <h1 class="bold small-top left-text full-bottom" style="padding-left: 5px">
      {{ lang[this.appLang].settings }}
    </h1>

    <div style="overflow-y: scroll; max-height: calc(92vh - 98px)">
      <!-- Public Profile Section -->
      <div
        v-if="!isEmpty(user) && user.is_active"
        id="public-section"
        class="content-boxed switch-section bg-white tiny-top"
        style="height: auto"
      >
        <div
          class="ios-switch"
          style="display: inline-block; margin: -2px 0 7px 10px; float: right"
        >
          <input
            v-model="user.is_public"
            type="checkbox"
            name="ios-switch"
            class="ios-switch-checkbox"
            :id="'public-switch'"
            :disabled="!rules.publicProfile"
          />
          <label class="ios-switch-label" :for="'public-switch'"></label>
        </div>
        <h5 class="pull-left regularbold">
          {{ lang[this.appLang].profile_public }}
        </h5>
        <p class="pull-left">
          {{ lang[this.appLang].profile_public_p }}
          <a class="bold pointer" @click="copy(profileLink)">{{
            profileLink
          }}</a>
        </p>
        <p v-if="!rules.publicProfile" class="pull-left">
          <b>{{ lang[this.appLang].forbidden_rule }} </b>
        </p>
      </div>

      <!-- Active Profile Section -->
      <div
        v-if="!isEmpty(user)"
        id="deactivate-section"
        class="content-boxed switch-section bg-white"
        style="height: auto; margin-bottom: 0"
      >
        <div
          class="ios-switch"
          style="display: inline-block; margin: -2px 0 7px 10px; float: right"
        >
          <input
            v-model="user.is_active"
            type="checkbox"
            name="ios-switch"
            class="ios-switch-checkbox"
            :id="'deactivate-switch'"
            :disabled="!rules.activeProfile"
          />
          <label class="ios-switch-label" :for="'deactivate-switch'"></label>
        </div>
        <h5 class="pull-left regularbold">
          {{ lang[this.appLang].profile_active }}
        </h5>
        <p class="pull-left">{{ lang[this.appLang].profile_active_p }}</p>
        <p v-if="!rules.activeProfile" class="pull-left">
          <b>{{ lang[this.appLang].forbidden_rule }} </b>
        </p>
      </div>

      <div class="center-text full-top">
        <button
          @click="togglePasswordModal"
          type="button"
          class="button white-button small-top button-full pointer"
          style="margin: 0 auto"
        >
          {{ lang[this.appLang].profile_change_password }}
        </button>
      </div>
      <div class="center-text half-top">
        <button
          @click="toggleUsernameModal"
          type="button"
          class="button white-button small-top button-full pointer"
          style="margin: 0 auto"
        >
          {{ lang[this.appLang].profile_change_username }}
        </button>
      </div>
      <div class="center-text half-top">
        <button
          @click="toggleLanguageModal"
          type="button"
          class="button white-button small-top button-full pointer"
          style="margin: 0 auto"
        >
          {{ lang[this.appLang].profile_change_language }}
        </button>
      </div>
      <div class="center-text half-top">
        <button
          @click="toggleDeleteAccountModal"
          type="button"
          class="button white-button small-top button-full pointer"
          style="margin: 0 auto"
        >
          {{ lang[this.appLang].profile_delete_account }}
        </button>
      </div>
      <p class="center-text font-13 half-top half-bottom">
        <span v-html="lang[this.appLang].profile_contact_support"></span>
        <a
          :href="
            'mailto:support@tapni.co?subject=' +
            lang[this.appLang].profile_email_subject
          "
          class="pointer color-black"
        >
          support@tapni.co</a
        >
      </p>
    </div>
  </form>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import { mapState, mapActions } from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      toggle: false,
    };
  },
  computed: {
    profileLink() {
      return `${this.$store.getters.subdomain}.tapni.co/${this.user.username}`;
    },
    rules() {
      return this.$store.getters.rules;
    },
  },
  mounted() {
    EventBus.$on("toggleProfileModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
  },
  methods: {
    ...mapActions(["saveProfile"]),
    activate() {
      EventBus.$emit("closeModal");
      this.$router.push("/tags#activate");
    },
    close() {
      EventBus.$emit("closeModal");
    },
    toggleModal() {
      this.toggle = !this.toggle;
    },
    async save() {
      await this.saveProfile({
        username: this.user.username,
        is_public: this.user.is_public,
        is_active: this.user.is_active,
        preventTopOver: true,
      });
      this.successSnack(this.lang[this.appLang].profile_update_success);
    },
    toggleUsernameModal() {
      if (!this.rules.editUsername)
        EventBus.$emit("toggleForbiddenRuleModal", false);
      else EventBus.$emit("toggleUsernameModal");
      this.toggleModal();
    },
    togglePasswordModal() {
      if (!this.rules.changePassword)
        EventBus.$emit("toggleForbiddenRuleModal", false);
      else EventBus.$emit("togglePasswordModal");
      this.toggleModal();
    },
    toggleLanguageModal() {
      EventBus.$emit("toggleLanguageModal", false);
      this.toggleModal();
    },
    toggleDeleteAccountModal() {
      EventBus.$emit("toggleDeleteAccountModal", false);
      this.toggleModal();
    },
  },
  watch: {
    "user.is_public"(ov) {
      if (this.toggle && ov !== undefined) {
        this.save();
      }
    },
    "user.is_active"(ov) {
      if (this.toggle && ov !== undefined) {
        this.save();
      }
    },
  },
};
</script>

<style scoped>
.profile-img {
  margin: 0 auto;
  margin-bottom: 10px;
  width: 55px;
}
</style>
