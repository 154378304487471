/* eslint-disable no-unused-vars */
import Vuex from 'vuex'
import Vue from 'vue'
import router from '../router'
import lang from './modules/lang'

import to from 'await-to-js'

import UserService from '../services/UserService'
import AuthService from '../services/AuthService'
import LinkService from '../services/LinkService'
import TagService from '../services/TagService'
import CompanyService from '../services/CompanyService'
import ScanService from '../services/ScanService'


import { EventBus } from './event-bus'
import axios from 'axios'
import { Capacitor } from '@capacitor/core';
import CONSTANTS from '../constants';
import { checkAnalyticsCookies } from '../plugins/analytics'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    lang
  },
  state: {
    appLanguage: 'en',
    demo: false,
    isMaintenance: false,
    isOutdatedVersion: true,
    requestedPayout: false,
    authUrl: '',
    token: '',
    refreshToken: '',
    loggedInUserId: '',
    user: {},
    localUser: {},
    company: {},
    affiliate: {},
    linkTypes: {},
    isCompanyLoaded: false,
    initialized: false,
    loading: true,
    loggedInAccounts: {},
    analytics: {}
  },
  getters: {
    appLang(state) {
      return state.appLanguage
    },
    isLoggedIn(state) {
      return !!state.token && state.token !== '' && state.token !== 'null'
    },
    directLink(state) {
      return state.user && state.user.links ? state.user.links.filter(l => l.is_direct)[0] : null
    },
    appVersion() {
      switch (Capacitor.getPlatform()) {
        case 'android': return CONSTANTS.ANDROID_VERSION
        case 'ios': return CONSTANTS.IOS_VERSION
        case 'web': return CONSTANTS.WEB_VERSION
      }
      return CONSTANTS.WEB_VERSION;
    },
    bundleVersion() {
      return CONSTANTS.WEB_VERSION || '0.0.0';
    },
    subdomain(state) {
      let subdomain = (state.company && state.company.subdomain) ? state.company.subdomain : window.location.hostname.split('.tapni.co')[0];
      if (state.route.query.s) subdomain = state.route.query.s;
      return (subdomain && subdomain !== 'localhost' && !subdomain.includes('192.168')) ? subdomain : 'my'
    },
    rules(state) {
      return state.user?.group?.rules || {
        "editName": true,
        "editBio": true,
        "editUsername": true,
        "activeProfile": true,
        "publicProfile": true,
        "changePassword": true,
        "editContactCard": true,
        "editProfilePicture": true,
        "addTags": true,
        "editCompanyTags": true,
        "addLinks": true,
        "editCompanyLinks": true
      }
    },
    forbiddenLinks(state) {
      return state.user?.group?.forbidden_links || []
    }
  },
  actions: {
    async linkClick({ }, data) {
      const [err, response] = await to(LinkService.linkClick(data))
      if (err) return this._vm.errorHandler(err)
      return response
    },
    async eventLog({ }, data) {
      const [err, response] = await to(UserService.eventLog(data))
      if (err) return this._vm.errorHandler(err)
      return response
    },
    async maintenance({ commit, getters }) {
      const [err, response] = await to(axios.get('https://status.tapni.co/data/maintenance.json'))
      if (err) return console.error(err);
      if (response) {
        let maintenanceState = response.data;
        if (typeof response.data === 'string') {
          maintenanceState = JSON.parse(response.data);
        }

        if (maintenanceState.api && maintenanceState.api.maintenanceActive) {
          commit('isMaintenance', true)
          router.push('/maintenance?msg=' + maintenanceState.api.msg)
        }

        if (maintenanceState.version && maintenanceState.version.minimumVersion) {
          if (parseFloat(maintenanceState.version.minimumVersion) > parseFloat(getters.appVersion)) {
            commit('isOutdatedVersion', true)
            router.push('/update?v=' + maintenanceState.version.minimumVersion)
          }
        }
      }
    },
    async getCompanyBySubdomain({ state, commit, rootState, dispatch }, data) {
      const [err, response] = await to(CompanyService.getBySubdomain(data.subdomain));
      if (err) return this._vm.errorHandler(err)
      if (response && response.data) {
        commit('setCompany', response.data)
        return response.data;
      }
      return null;
    },
    async getUser({ state, getters, commit, dispatch, rootState }, data) {
      if (data) {
        let err, response
        if (data.username) [err, response] = await to(UserService.getByUsername(data))
        else if (data.serial) [err, response] = await to(UserService.getByNumber(data))
        if (err) return this._vm.errorHandler(err)
        if (response.data.success) {
          if (!response.data.user && response.data.showDemoProfile) {
            if (getters.isLoggedIn) {
              router.push('/tags#activate')
              throw new Error('Activate the tag')
            } else {
              return router.push('/' + response.data.showDemoProfile)
            }
          }


          let metapixelAnalytics;
          let googleAnalytics;

          // if user belongs to  company
          if (Object.keys(response.data.company).length > 0) {
            commit('setCompany', response.data.company)

            const { Integrations } = response.data.company;
            if (Integrations?.length) {
              Integrations.forEach(integration => {
                if (integration.analytic === 'metapixel-analytics') metapixelAnalytics = integration.key;
                if (integration.analytic === 'google-analytics') googleAnalytics = integration.key;
              });
            }
          } else {
            metapixelAnalytics = CONSTANTS.METAPIXEL_ID;
            googleAnalytics = CONSTANTS.GOOGLE_ANALYTICS_ID;
          }

          commit('setAnalytics', { metapixelAnalytics, googleAnalytics })

          // show cookie policy only on web app
          if(!Capacitor.isNativePlatform()) checkAnalyticsCookies({ metapixelAnalytics, googleAnalytics })

          if (!response.data.user && data.login) {
            dispatch('logout')
          }

          dispatch('lang/registerLang', response.data.user ? response.data.user.lang : 'en')
          commit('setUser', response.data.user)

          // Fetch affiliate
          if (state.user && state.user.id) dispatch('getAffiliate', state.user.id)
        }
      }
    },

    loginSetup({ state, getters, commit, rootState, dispatch }, response) {
      commit('setRefreshToken', response.data.refreshToken)
      commit('setToken', response.data.token)

      if (response.data.data.lang !== getters.appLang) {
        response.data.data.lang = getters.appLang
        dispatch('saveProfile', { lange: getters.appLang, username: response.data.data.username })
      }

      commit('setUser', response.data.data)
      commit('setLoggedInUserId', response.data.data.id)
      commit('setInitialize', true)

      this._vm.storage.UserId = response.data.data.id
      this._vm.storage.username = response.data.data.username

      if (response.data.data.company) {
        commit('setCompany', response.data.data.company)
      }

      if (response.isModal === true) {
        commit('setLoggedInAccounts', [{
          id: response.data.data.id,
          username: response.data.data.username,
          refreshToken: response.data.refreshToken,
          photo: response.data.data.photo
        }])
      }
    },
    async refreshTokenAction({ state, commit, rootState, dispatch }, data) {
      const [err, response] = await to(axios.get(process.env.VUE_APP_API_ROOT + '/v1/users/refresh-token', {
        params: {
          UserId: data.id,
          token: state.refreshToken
        }
      }))
      if (err && err.response && err.response.data.error === 'ACCESS_DENIED') {
        dispatch('logout', false);
        return location.reload();
      }

      // Set new access token
      commit('setToken', response.data.token)
    },
    async login({ state, commit, rootState, dispatch, getters }, data) {
      const [err, response] = await to(AuthService.login(data))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        dispatch('getLinkTypes')
        dispatch('loginSetup', { ...response, isModal: data.isModal })
        router.push('/' + response.data.data.username + '#edit')
        if (data.isModal) {
          EventBus.$emit('closeModal')
          this._vm.successSnack(rootState.lang[getters.appLang].success_login)
        }
        dispatch('getLoggedInAccounts', this._vm.storage.refreshTokens.split(','))
        return true
      }
    },
    async register({ state, commit, rootState }, data) {
      const [err, response] = await to(AuthService.register(data))
      if (err) return this._vm.errorHandler(err)
      return response
    },
    async verify({ state, commit, rootState }, data) {
      const [err, response] = await to(AuthService.verify(data))
      if (err) return this._vm.errorHandler(err)
      return response
    },
    async reset({ state, commit, rootState, getters }, data) {
      const [err, response] = await to(AuthService.sendResetEmail(data))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        this._vm.successSnack(rootState.lang[getters.appLang].password_reset_success)
      }
    },
    async changePassword({ state, commit, rootState }, data) {
      const [err, response] = await to(AuthService.changePassword(data))
      if (err) return this._vm.errorHandler(err)
      return response.data.success
    },
    async newPassword({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.newPassword(data))
      if (err) return this._vm.errorHandler(err)
      return response.data.success
    },
    async deleteAccount({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.deleteAccount(data))
      if (err) return this._vm.errorHandler(err)
      return response.data.success
    },
    async affiliatePayout({ state }) {
      const [err, response] = await to(UserService.affiliatePayout())
      if (err) return this._vm.errorHandler(err)
      state.requestedPayout = true
      return response
    },
    async acceptCompanyInvitation({ commit }, code) {
      const [err, response] = await to(CompanyService.acceptCompanyInvitation(code))
      if (err) return this._vm.errorHandler(err)
      return response
    },
    async getAffiliate({ commit }, UserId) {
      const [err, response] = await to(UserService.getAffiliate(UserId))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        commit('setAffiliate', response.data.affiliate)
      }
    },
    async referralApply({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.referralApply(data))
      if (err) return this._vm.errorHandler(err)
      return response.data.success
    },
    async logout({ state, commit, dispatch }, sendRequest = true) {
      if (sendRequest) {
        const [err] = await to(AuthService.logout({
          token: state.refreshToken,
        }))
        if (err) return this._vm.errorHandler(err)
      }

      this._vm.storage.username = null
      this._vm.storage.localUser = null
      commit('setLoggedInUserId', null)
      commit('setToken', null)
      commit('setRefreshToken', null)
      commit('setCompany', null)

      if (state.refreshToken) {
        Object.keys(state.loggedInAccounts).forEach(username => {
          if (state.loggedInAccounts[username].refreshToken === state.refreshToken) {
            dispatch('refreshTokenAction', { id: state.loggedInAccounts[username].id })
              .then(() => {
                commit('setLoggedInUserId', state.loggedInAccounts[username].id)
                this._vm.storage.username = username
                this._vm.storage.UserId = state.loggedInAccounts[username].id
                dispatch('getLoggedInAccounts', this._vm.storage.refreshTokens.split(','))
                return router.push('/' + username)
              })
          }
        })
      } else commit('setUser', null)
      router.push('/welcome')
    },
    async getLoggedInAccounts({ commit }, refreshTokens) {
      const [err, response] = await to(UserService.getLoggedInAccounts({ refreshTokens }))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        commit('setLoggedInAccounts', response.data.accounts)
      }
    },
    async getLinkTypes({ commit }) {
      const [err, response] = await to(LinkService.getTypes())
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        commit('setLinkTypes', response.data.types)
      }
    },
    async saveProfile({ state, commit, dispatch }, data) {
      const [err, response] = await to(UserService.save(data))
      EventBus.$emit('loadingHeader', false)
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        this._vm.storage.username = data.username
        if (!data.preventTopOver) {
          router.push('/' + data.username + '#edit')
        }
        return response.data.success
      }
    },
    async removeLink({ state, commit, dispatch, rootState, getters }, data) {
      const [err, response] = await to(LinkService.remove(data))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        dispatch('getUser', state.user)
        this._vm.successSnack(rootState.lang[getters.appLang].success_remove_link)
      }
    },
    async addOrUpdateLink({ state, commit, dispatch }, data) {
      EventBus.$emit('loadingHeader', true)
      let err, response
      if (data.id) {
        [err, response] = await to(LinkService.edit(data))
      } else {
        [err, response] = await to(LinkService.add(data))
      }
      setTimeout(() => {
        EventBus.$emit('loadingHeader', false)
      }, 1000)
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        dispatch('getUser', state.user)
      }
    },
    async updateVCard({ state, commit, dispatch }, data) {
      EventBus.$emit('loadingHeader', true)
      let [err, response] = await to(UserService.updateVCard(data))
      setTimeout(() => {
        EventBus.$emit('loadingHeader', false)
      }, 1000)
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        dispatch('getUser', state.user)
      }
    },
    async updateLinkStyle({ state, commit }, link_style) {
      EventBus.$emit('loadingHeader', true)
      const [err, response] = await to(UserService.updateLinkStyle({ link_style }))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        setTimeout(() => {
          EventBus.$emit('loadingHeader', false)
        }, 1000)
      }
    },
    async updateIsActive({ state, commit, rootState, getters }, isActive) {
      const [err, response] = await to(UserService.updateIsActive({ isActive }))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        if (isActive) {
          this._vm.successSnack(rootState.lang[getters.appLang].profile_active_message)
        } else {
          this._vm.successSnack(rootState.lang[getters.appLang].profile_inactive_message)
        }
      }
    },
    async updateIsPublic({ state, commit, rootState, getters }, isPublic) {
      const [err, response] = await to(UserService.updateIsPublic({ isPublic }))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        if (isPublic) {
          this._vm.successSnack(rootState.lang[getters.appLang].profile_public_message)
        } else {
          this._vm.successSnack(rootState.lang[getters.appLang].profile_private_message)
        }
      }
    },
    async updateIsDirect({ state, commit, rootState, getters }, data) {
      const [err, response] = await to(LinkService.updateIsDirect(data))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        if (data.is_direct) {
          this._vm.successSnack(rootState.lang[getters.appLang].redirect_enabled)
        } else {
          this._vm.successSnack(rootState.lang[getters.appLang].redirect_disabled)
        }
      }
    },
    async updateTag({ state, commit, rootState, getters }, data) {
      const [err, response] = await to(TagService.edit(data))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        if (data.is_active) {
          this._vm.successSnack(rootState.lang[getters.appLang].tag_active)
        } else {
          this._vm.successSnack(rootState.lang[getters.appLang].tag_inactive)
        }
      }
    },
    async removeTag({ state, commit, dispatch, rootState, getters }, data) {
      const [err, response] = await to(TagService.remove(data))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        dispatch('getUser', state.user)
        this._vm.successSnack(rootState.lang[getters.appLang].tag_removed)
      }
    },
    async activate({ state, commit, rootState }, data) {
      if (data.serial) {
        data.serial = data.serial.replace(/:/g, '').replace(/ /g, '').toLowerCase()
        const [err, response] = await to(TagService.activate(data))
        if (err) return this._vm.errorHandler(err)
        return response
      }
    },
    async connect({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.connect(data))
      if (err) return this._vm.errorHandler(err)
      return response
    },
    async saveConnection({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.saveConnection(data))
      if (err) return this._vm.errorHandler(err)
      return response
    },
    async deleteConnection({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.deleteConnection(data))
      if (err) return this._vm.errorHandler(err)
      return response
    },
    async createConnection(context, data) {
      const [err, response] = await to(UserService.createConnection(data))
      if (err) return this._vm.errorHandler(err)
      return response
    },
    async accept({ state, commit, rootState, getters }, data) {
      const [err, response] = await to(UserService.accept(data))
      if (err) return this._vm.errorHandler(err)
      if (response.data.success) {
        this._vm.successSnack(rootState.lang[getters.appLang].connection_accepted)
      }
    },
    async getConnections(context, params) {
      const [err, response] = await to(UserService.getConnections(params))
      if (err) return this._vm.errorHandler(err)
      return response
    },
    async createCardScan({ state, commit, rootState }, data) {
      const [err, response] = await to(ScanService.createCardScan(data))
      if (err) return this._vm.errorHandler(err)
      return response
    }
  },
  mutations: {
    setLoggedInAccounts(state, accounts) {
      state.loggedInAccounts = {}
      accounts.forEach(account => {
        state.loggedInAccounts[account.username] = account
      })
    },
    setAffiliate(state, affiliate) {
      state.affiliate = affiliate
    },
    setLinkTypes(state, linkTypes) {
      state.linkTypes = linkTypes
    },
    async updateLinks(state, links) {
      if (links) {
        links.forEach((link, i) => {
          link.order = i + 1
        })
        const currentLinks = state.user.links
        state.user.links = links
        if (JSON.stringify(currentLinks) !== JSON.stringify(links)) {
          EventBus.$emit('loadingHeader', true)
          const [err, response] = await to(LinkService.updateLinksOrder(links))
          if (err) return this._vm.errorHandler(err)
          if (response.data.success) {
            setTimeout(() => {
              EventBus.$emit('loadingHeader', false)
            }, 1000)
          }
        }
      }
    },
    setLoggedInUserId(state, id) {
      state.loggedInUserId = id
    },
    isMaintenance(state, isMaintenance) {
      state.isMaintenance = isMaintenance
    },
    isOutdatedVersion(state, isOutdatedVersion) {
      state.isOutdatedVersion = isOutdatedVersion
    },
    setUser(state, user) {
      state.user = user
      if (this._vm.storage && state.user && this._vm.storage.username === state.user.username) {
        state.appLanguage = user.lang
        this._vm.storage.localUser = user;
      }
    },
    setCompany(state, company) {
      state.company = company
      state.isCompanyLoaded = true
    },
    setRefreshToken(state, token) {
      let refreshTokens = this._vm.storage.refreshTokens.split(',')
      if (token && !refreshTokens.includes(token)) refreshTokens.unshift(token)
      else refreshTokens = refreshTokens.filter(t => t !== state.refreshToken)

      if (refreshTokens.length >= 1) state.refreshToken = refreshTokens[0]
      else state.refreshToken = token

      this._vm.storage.refreshTokens = refreshTokens.join(',')
    },
    setToken(state, token) {
      this._vm.storage.token = token
      state.token = token
    },
    setInitialize(state, initialized) {
      state.initialized = initialized
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setLanguage(state, lang) {
      state.appLanguage = lang
      if (this._vm.storage && state.user && state.user.lang && this._vm.storage?.username === state.user.username) {
        state.user.lang = lang
      }
    },
    setAnalytics(state, data){
        state.analytics.googleAnalytics = data.googleAnalytics;
        state.analytics.metapixelAnalytics = data.metapixelAnalytics;
    }
  }
})
