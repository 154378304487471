<template>
  <div
    id="qrScannerBindId"
    class="menu-wrapper menu-light menu-modal activate-page center-text"
    :class="{ 'active-menu': toggle }"
    :style="`overflow-y: hidden; height: ${
      !scanning ? 'auto' : '80vh'
    }; max-height: 90vh`"
  >
    <a
      v-if="scanning"
      @click="close"
      class="color-orange-dark pull-right pointer"
      style="margin-top: 10px; position: absolute; right: 15px; z-index: 3000; font-size: 20px;"
      >✖</a
    >
    <a
      v-else
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 10px; position: absolute; right: 0px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>
    <div v-show="!scanning" class="full-top">
      <img
        src="https://cdn.tapni.co/images/qr-scan-animation.gif"
        class="scan-gif"
      />
      <!--<img src="https://cdn.tapni.co/icons/scan.png" class="user-link-img" style="width: 110px; margin: 0 auto;" />-->
      <button
        class="button white-button small-top pointer scan-button"
        @click="turnCameraOn"
      >
        {{ lang[this.appLang].scan_qr_code }}
      </button>

      <p
        v-if="!enterManually"
        class="
          center-text
          small-top
          half-bottom
          font-15
          underline
          pointer
          color-black
        "
        @click="enterManually = true"
      >
        Enter the code manually
      </p>

      <form @submit.prevent="activateTag(code, true)" v-if="enterManually">
        <input
          id="activationCode"
          type="text"
          v-model="code"
          :placeholder="lang[this.appLang].activation_code"
          class="edit-input full-top h-40 lh-40"
          style="margin: 0 auto; width: 70%; font-size: 15px"
        />
        <p
          class="
            close-text close-menu
            center-text
            small-top small-bottom
            font-14
          "
        >
          {{ lang[this.appLang].enter_activation_code }}
        </p>
        <button
          type="submit"
          class="button white-button small-top pointer"
          style="margin: 0 auto; width: 70%"
        >
          {{ lang[this.appLang].activate_tag }}
        </button>
      </form>
      <p class="center-text font-13 half-top full-bottom">
        <span v-html="lang[this.appLang].order_tapni" />
        <router-link
          to="/products"
          exact
          class="color-black bold"
          @click.native="close"
          >{{ lang[this.appLang].by_clicking_here }}</router-link
        >
      </p>
    </div>

    <qrcode-stream
      v-show="scanning"
      :camera="camera"
      @decode="onDecode"
      @init="onInit"
    >
      <div
        v-if="validationSuccess"
        class="validation-success"
        v-html="lang[this.appLang].activation_success"
      />

      <div
        v-if="validationFailure"
        class="validation-failure"
        v-html="lang[this.appLang].activation_error"
      />

      <div
        v-if="validationPending"
        class="validation-pending"
        v-html="lang[this.appLang].activation_progress"
      />
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

import { EventBus } from "../../store/event-bus";
import { mapActions } from "vuex";
export default {
  name: "QRScanner",
  components: { QrcodeStream },

  data() {
    return {
      isValid: undefined,
      camera: "off",
      result: null,
      toggle: false,
      loading: false,
      code: "",
      scanning: false,
      enterManually: false,
    };
  },
  computed: {
    validationPending() {
      return this.isValid === null && this.camera === "off";
    },
    validationSuccess() {
      return this.isValid === true;
    },
    validationFailure() {
      return this.isValid === false;
    },
  },
  mounted() {
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
    EventBus.$on("toggleQRScanModal", this.toggleModal);
    EventBus.$on("closeNativeScanner", () => {
      BarcodeScanner.stopScan();
      BarcodeScanner.showBackground();
      document.getElementById('rootAppId').style.display = '';
      document.getElementById('scannerOverlay').style.display = 'none';
      document.getElementById("bodyId").style["background-color"] = '#ffffff'
      this.close();
    });
  },
  methods: {
    ...mapActions(["getUser", "activate"]),
    toggleModal() {
      this.toggle = !this.toggle;
    },
    close() {
      this.enterManually = false;
      this.code = "";
      if (this.scanning) {
        this.resetValidationState();
        this.camera = "off";
        this.scanning = false;
      } else EventBus.$emit("closeModal");
    },
    onInit(promise) {
      promise.catch(console.error).then(this.resetValidationState);
    },

    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content) {
      this.result = content;
      this.isValid = null;

      if (!content.includes("t/")) {
        setTimeout(this.close, 2000);
      }

      await this.activateTag(content.split("t/")[1]);
    },

    async activateTag(serial, formSubmitted = false) {
      if (!serial)
        return this.errorSnack(this.lang[this.appLang].activation_invalid_code);

      const response = await this.activate({ serial });

      this.isValid = response && response.data ? response.data.success : false;

      await this.getUser({ username: this.user.username });
      if (formSubmitted) {
        if (this.isValid === true) {
          this.successSnack(this.lang[this.appLang].activation_success_message);
        } else {
          this.errorSnack(this.lang[this.appLang].activation_error_message);
        }
      } else {
        // some more delay, so users have time to read the message
        await this.timeout(2000);
      }

      this.scanning = false;
      if (this.validationSuccess) {
        this.close();
        this.$forceUpdate();
      }
      this.turnCameraOff();
      this.resetValidationState();
    },
    async turnCameraOn() {
      // eslint-disable-next-line no-undef
      if (this.isNative) {
        setTimeout(() => {
          this.scanning = true;
        }, 1000);
        // eslint-disable-next-line no-undef
        /*
        cordova.plugins.barcodeScanner.scan(
          (result) => {
            if (!result.cancelled) {
              this.onDecode(result.text);
            } else this.close();
          },
          () => {
            this.errorSnack(this.lang[this.appLang].activation_qr_failed);
          },
          {
            preferFrontCamera: false, // iOS and Android
            showFlipCameraButton: false, // iOS and Android
            showTorchButton: false, // iOS and Android
            torchOn: false, // Android, launch with the torch switched on (if available)
            saveHistory: false, // Android, save scan history (default false)
            prompt: this.lang[this.appLang].activation_qr_area, // Android
            resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
            formats: "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
            orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
            disableAnimations: false, // iOS
            disableSuccessBeep: true, // iOS and Android
          }
        );
        */
        let status = await BarcodeScanner.checkPermission({ force: true });
        if (status.denied) {
          // the user denied permission for good
          // redirect user to app settings if they want to grant it anyway
          const c = confirm('If you want to grant permission for using your camera, enable it in the app settings.');
          if (c) {
            BarcodeScanner.openAppSettings();
          }
        }
        BarcodeScanner.hideBackground();
        document.getElementById('rootAppId').style.display ='none';
        document.getElementById('scannerOverlay').style.display = '';
        document.getElementById("bodyId").style["background-color"] = 'transparent'
        try {
          let result = await BarcodeScanner.startScan();
          BarcodeScanner.showBackground();
          document.getElementById('rootAppId').style.display = '';
          document.getElementById('scannerOverlay').style.display = 'none';
          document.getElementById("bodyId").style["background-color"] = '#ffffff'
          if(result && result.hasContent && result.content) this.onDecode(result.content);
          else this.errorSnack(this.lang[this.appLang].activation_qr_failed);
        }catch (e) {
          BarcodeScanner.showBackground();
          document.getElementById('rootAppId').style.display = '';
          document.getElementById('scannerOverlay').style.display = 'none';
          document.getElementById("bodyId").style["background-color"] = '#ffffff'
        }
      } else {
        this.scanning = true;
        this.camera = "auto";
      }
    },
    turnCameraOff() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
};
</script>

<style scoped>
button {
  margin-bottom: 20px;
}

.loading-indicator {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.82);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: #0576a3;
}
.validation-failure {
  color: red;
}
.menu-wrapper {
  margin-top: -172.5px;
  padding: 0;
}

.scan-gif {
  width: 70%;
  margin: 0 auto;
  border-radius: 20px;
}

.scan-button {
  display: block;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 20px;
}
</style>
