import Api from './Api'

export default {
  getBySubdomain (subdomain) {
    return Api().get(`/company/subdomain/${subdomain}`)
  },
  acceptCompanyInvitation (code) {
    return Api().get(`/users/invitation?ic=${code}`)
  },
}
