<template>
  <form
    @submit.prevent="submit"
    class="menu-wrapper menu-light menu-modal center-text activate-page"
    :class="{ 'active-menu': toggle }"
    style="height: auto; margin-top: -172.5px; max-height: 90vh"
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>
    <h3 v-if="user" class="center-text bold small-top half-bottom">
      {{ this.lang[this.appLang].connect_with }} <br />
      {{ user.name }}
    </h3>

    <input
      type="text"
      v-model="name"
      name="name"
      autocomplete="true"
      :placeholder="lang[this.appLang].name"
      class="edit-input half-bottom"
      style="margin: 0 auto; width: 90%;"
    />

    <input type="email" name="email" autocomplete="true" v-model="email" :placeholder="lang[this.appLang].email"
             class="edit-input half-bottom" style="margin: 0 auto; width: 90%;"/>

    <div
      style="width: 65%; display: inline-block; margin: 0 auto"
      v-if="!showDetails"
    >
      <p @click="showDetails = true" class="center-text small-bottom pointer underline color-black" style="font-weight: 500;">{{
        lang[this.appLang].add_details
      }}</p>
    </div>

    <input
      type="text"
      v-model="phone"
      v-show="showDetails"
      :placeholder="lang[this.appLang].phone"
      class="edit-input half-bottom"
      style="margin: 0 auto; width: 90%;"
    />

    <input
      type="text"
      v-model="title"
      v-show="showDetails"
      :placeholder="lang[this.appLang].contact_card_job_title"
      class="edit-input half-bottom" style="margin: 0 auto; width: 90%;" />

    <input
      type="text"
      v-model="companyName"
      v-show="showDetails"
      :placeholder="lang[this.appLang].company"
      class="edit-input half-bottom"
      style="margin: 0 auto; width: 90%;"
    />

    <textarea
      v-autogrow
      v-model="note"
      v-show="showDetails"
      :placeholder="lang[this.appLang].note_p"
      class="edit-input half-bottom"
      rows="2"
      style="width: 90%; margin: 0 auto; min-height: 80px; resize: none"
    />

    <button
      type="submit"
      :disabled="user && user.connected"
      class="button black-button half-top pointer"
      style="margin: 0 auto; width: 90%"
    >
      {{ lang[this.appLang].exchange_contact }}
    </button>

    <p style="margin: 0 auto; margin-top: 20px" v-if="user && user.vcard">
      {{ lang[this.appLang].or }}
      <a
        @click="downloadContact"
        style="
          text-decoration: underline;
          text-underline-position: under;
          display: inline;
        "
        class="color-black small-top pointer"
        >{{ lang[this.appLang].download_contact }}</a
      >
    </p>

    <div class="decoration decoration-lines-thin no-bottom"></div>

    <p class="close-text center-text half-top color-black">
      {{ lang[this.appLang].terms_by_submitting }} <br />
      <a class="" href="https://tapni.co/policies/terms-of-service">{{
        lang[this.appLang].terms_of_service
      }}</a>
      {{ lang[this.appLang].and }}
      <a href="https://tapni.co/policies/privacy-policy">{{
        lang[this.appLang].privacy_policy
      }}</a>
    </p>
  </form>
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapState, mapActions, mapGetters} from 'vuex'
export default {
  name: "Connect",
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      companyName: '',
      title: '',
      note: '',
      toggle: false,
      showDetails: false,
    };
  },
  mounted() {
    EventBus.$on("toggleConnectModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
  },
  methods: {
    ...mapActions(["connect", "getUser"]),
    join() {
      this.close();
      this.$router.push("/register");
    },
    close() {
      this.showDetails = false;
      EventBus.$emit("closeModal");
    },
    async submit() {
      if (!this.name) {
        return this.errorSnack(this.lang[this.appLang].connect_validate_name);
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!this.email || !emailRegex.test(this.email)) {
        return this.errorSnack(this.lang[this.appLang].connect_validate_email);
      }
      await this.connect({
        name: this.name,
        email: this.email,
        phone: this.phone,
        company: this.companyName,
        title: this.title,
        note: this.note,
        UserId: this.user.id,
      });
      this.user.connected = true;
      //this.successSnack(this.lang[this.appLang].exchange_success)
      this.close();
      EventBus.$emit("toggleAfterConnectModal");
      if (Object.keys(this.user.vcard).length > 0) {
        const url =
          process.env.VUE_APP_API_ROOT + "/v1/users/" + this.user.id + "/vcard";
        this.downloadContactCard(url);
      }
      this.$forceUpdate();
    },
    downloadContact() {
      const url =
        process.env.VUE_APP_API_ROOT + "/v1/users/" + this.user.id + "/vcard";
      this.close();
      EventBus.$emit("toggleAfterConnectModal");
      this.downloadContactCard(url);
    },
    toggleModal() {
      this.toggle = !this.toggle;
    },
  },
};
</script>

<style scoped>
.link-img {
  margin: 0 auto;
  width: 55px;
}
#linkText {
  font-size: 22px;
}

#linkUrl {
  font-size: 15px;
}
input {
  margin: 7px 0;
  margin: 0 auto;
  height: 40px;
}

.checkBox {
  display: inline-block !important;
  vertical-align: middle;
  height: 21px;
  width: 21px;
  float: left;
  margin-top: 5px;
}
</style>
