var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-wrapper menu-light menu-modal activate-page",class:{ 'active-menu': _vm.toggle },staticStyle:{"height":"auto","margin-top":"-172.5px"}},[_c('a',{staticClass:"color-black pull-right pointer",staticStyle:{"margin-top":"5px","position":"absolute","right":"15px","text-align":"right"},on:{"click":_vm.close}},[_vm._m(0)]),_c('h3',{staticClass:"bold small-top half-bottom left-text",staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm.lang[this.appLang].profile_complete)+" ")]),_c('div',{staticStyle:{"overflow-y":"scroll","max-height":"calc(92vh - 83px)"}},[(!_vm.isEmpty(_vm.user))?_c('div',{class:{
        'content-boxed switch-section bg-white border-black': true,
        'no-border pointer': !_vm.verifiedEmail,
      },staticStyle:{"height":"auto"},on:{"click":_vm.verifyEmail}},[_c('div',{staticClass:"ios-switch",staticStyle:{"position":"absolute","right":"10px","width":"30px"}},[(_vm.verifiedEmail)?_c('img',{staticClass:"responsive-image",attrs:{"src":"https://cdn.tapni.co/icons/check-black.png","alt":_vm.lang[this.appLang].completed}}):_c('img',{staticClass:"responsive-image opacity-40",attrs:{"src":"https://cdn.tapni.co/icons/check-black.png","alt":_vm.lang[this.appLang].uncompleted}})]),_c('h5',{staticClass:"pull-left bold"},[_vm._v(_vm._s(_vm.lang[this.appLang].verify_email))]),_c('br'),_c('p',{staticClass:"pull-left"},[_vm._v(" "+_vm._s(_vm.lang[this.appLang].verify_email_inbox)+" "),_c('b',[_vm._v(_vm._s(_vm.user.email))]),_c('br'),_vm._v(" "+_vm._s(_vm.lang[this.appLang].or)+" "),_c('a',{staticClass:"bold color-black pointer",attrs:{"href":'mailto:support@tapni.co?subject=' +
            _vm.lang[this.appLang].verify_email_subject}},[_vm._v(_vm._s(_vm.lang[this.appLang].contact_us))])])]):_vm._e(),(!_vm.isEmpty(_vm.user))?_c('div',{class:{
        'content-boxed switch-section bg-white border-black': true,
        'no-border pointer': !_vm.addedLink,
      },staticStyle:{"height":"auto"},on:{"click":_vm.addLink}},[_c('div',{staticClass:"ios-switch",staticStyle:{"position":"absolute","right":"10px","width":"30px"}},[(_vm.addedLink)?_c('img',{staticClass:"responsive-image",attrs:{"src":"https://cdn.tapni.co/icons/check-black.png","alt":_vm.lang[this.appLang].completed}}):_c('img',{staticClass:"responsive-image opacity-40",attrs:{"src":"https://cdn.tapni.co/icons/check-black.png","alt":_vm.lang[this.appLang].uncompleted}})]),_c('h5',{staticClass:"pull-left bold"},[_vm._v(_vm._s(_vm.lang[this.appLang].add_first_link))]),_c('br'),_c('p',{staticClass:"pull-left",domProps:{"innerHTML":_vm._s(_vm.lang[this.appLang].add_first_link_p)}})]):_vm._e(),(!_vm.isEmpty(_vm.user))?_c('div',{class:{
        'content-boxed switch-section bg-white border-black': true,
        'no-border pointer': !_vm.addedPhoto,
      },staticStyle:{"height":"auto"},on:{"click":_vm.addPhoto}},[_c('div',{staticClass:"ios-switch",staticStyle:{"position":"absolute","right":"10px","width":"30px"}},[(_vm.addedPhoto)?_c('img',{staticClass:"responsive-image",attrs:{"src":"https://cdn.tapni.co/icons/check-black.png","alt":_vm.lang[this.appLang].completed}}):_c('img',{staticClass:"responsive-image opacity-40",attrs:{"src":"https://cdn.tapni.co/icons/check-black.png","alt":_vm.lang[this.appLang].uncompleted}})]),_c('h5',{staticClass:"pull-left bold"},[_vm._v(_vm._s(_vm.lang[this.appLang].add_picture))]),_c('br'),_c('p',{staticClass:"pull-left"},[_vm._v(_vm._s(_vm.lang[this.appLang].add_picture_p))])]):_vm._e(),(!_vm.isEmpty(_vm.user))?_c('div',{class:{
        'content-boxed switch-section bg-white border-black': true,
        'no-border pointer': !_vm.addedTag,
      },staticStyle:{"height":"auto"},on:{"click":_vm.addTag}},[_c('div',{staticClass:"ios-switch",staticStyle:{"position":"absolute","right":"10px","width":"30px"}},[(_vm.addedTag)?_c('img',{staticClass:"responsive-image",attrs:{"src":"https://cdn.tapni.co/icons/check-black.png","alt":_vm.lang[this.appLang].completed}}):_c('img',{staticClass:"responsive-image opacity-40",attrs:{"src":"https://cdn.tapni.co/icons/check-black.png","alt":_vm.lang[this.appLang].uncompleted}})]),_c('h5',{staticClass:"pull-left bold"},[_vm._v(_vm._s(_vm.lang[this.appLang].add_tag))]),_c('br'),_c('p',{staticClass:"pull-left"},[_vm._v(_vm._s(_vm.lang[this.appLang].add_tag_p))])]):_vm._e(),_c('hr',{staticClass:"decoration decoration-lines-thin color-black small-bottom half-top"}),_c('p',{staticClass:"center-text font-13 half-top half-bottom"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.lang[this.appLang].completing_profile)}}),_c('a',{staticClass:"pointer color-black",attrs:{"href":'mailto:support@tapni.co?subject=' +
          _vm.lang[this.appLang].completing_profile_subject}},[_vm._v(" support@tapni.co")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticClass:"font-17 fa color-black"},[_c('img',{staticClass:"fa responsive-image",staticStyle:{"width":"45%"},attrs:{"src":"https://cdn.tapni.co/icons/down-arrow.png"}})])
}]

export { render, staticRenderFns }