<template>
  <div
    class="menu-wrapper menu-light menu-modal activate-page"
    :class="{ 'active-menu': toggle }"
    style="height: auto; margin-top: -172.5px"
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="
        margin-top: 5px;
        position: absolute;
        right: 15px;
        text-align: right;
      "
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>
    <h3 class="bold small-top half-bottom left-text" style="margin-left: 5px">
      {{ lang[this.appLang].profile_complete }}
    </h3>

    <div style="overflow-y: scroll; max-height: calc(92vh - 83px)">
      <!-- Verify Email -->
      <div
        v-if="!isEmpty(user)"
        @click="verifyEmail"
        :class="{
          'content-boxed switch-section bg-white border-black': true,
          'no-border pointer': !verifiedEmail,
        }"
        style="height: auto"
      >
        <div
          class="ios-switch"
          style="position: absolute; right: 10px; width: 30px"
        >
          <img
            v-if="verifiedEmail"
            class="responsive-image"
            src="https://cdn.tapni.co/icons/check-black.png"
            :alt="lang[this.appLang].completed"
          />
          <img
            v-else
            class="responsive-image opacity-40"
            src="https://cdn.tapni.co/icons/check-black.png"
            :alt="lang[this.appLang].uncompleted"
          />
        </div>
        <h5 class="pull-left bold">{{ lang[this.appLang].verify_email }}</h5>
        <br />
        <p class="pull-left">
          {{ lang[this.appLang].verify_email_inbox }} <b>{{ user.email }}</b>
          <br />
          {{ lang[this.appLang].or }}
          <a
            :href="
              'mailto:support@tapni.co?subject=' +
              lang[this.appLang].verify_email_subject
            "
            class="bold color-black pointer"
            >{{ lang[this.appLang].contact_us }}</a
          >
        </p>
      </div>

      <!-- Add Links -->
      <div
        v-if="!isEmpty(user)"
        @click="addLink"
        :class="{
          'content-boxed switch-section bg-white border-black': true,
          'no-border pointer': !addedLink,
        }"
        style="height: auto"
      >
        <div
          class="ios-switch"
          style="position: absolute; right: 10px; width: 30px"
        >
          <img
            v-if="addedLink"
            class="responsive-image"
            src="https://cdn.tapni.co/icons/check-black.png"
            :alt="lang[this.appLang].completed"
          />
          <img
            v-else
            class="responsive-image opacity-40"
            src="https://cdn.tapni.co/icons/check-black.png"
            :alt="lang[this.appLang].uncompleted"
          />
        </div>
        <h5 class="pull-left bold">{{ lang[this.appLang].add_first_link }}</h5>
        <br />
        <p class="pull-left" v-html="lang[this.appLang].add_first_link_p" />
      </div>

      <!-- Add Photo -->
      <div
        v-if="!isEmpty(user)"
        @click="addPhoto"
        :class="{
          'content-boxed switch-section bg-white border-black': true,
          'no-border pointer': !addedPhoto,
        }"
        style="height: auto"
      >
        <div
          class="ios-switch"
          style="position: absolute; right: 10px; width: 30px"
        >
          <img
            v-if="addedPhoto"
            class="responsive-image"
            src="https://cdn.tapni.co/icons/check-black.png"
            :alt="lang[this.appLang].completed"
          />
          <img
            v-else
            class="responsive-image opacity-40"
            src="https://cdn.tapni.co/icons/check-black.png"
            :alt="lang[this.appLang].uncompleted"
          />
        </div>
        <h5 class="pull-left bold">{{ lang[this.appLang].add_picture }}</h5>
        <br />
        <p class="pull-left">{{ lang[this.appLang].add_picture_p }}</p>
      </div>

      <!-- Add Tags -->
      <div
        v-if="!isEmpty(user)"
        @click="addTag"
        :class="{
          'content-boxed switch-section bg-white border-black': true,
          'no-border pointer': !addedTag,
        }"
        style="height: auto"
      >
        <div
          class="ios-switch"
          style="position: absolute; right: 10px; width: 30px"
        >
          <img
            v-if="addedTag"
            class="responsive-image"
            src="https://cdn.tapni.co/icons/check-black.png"
            :alt="lang[this.appLang].completed"
          />
          <img
            v-else
            class="responsive-image opacity-40"
            src="https://cdn.tapni.co/icons/check-black.png"
            :alt="lang[this.appLang].uncompleted"
          />
        </div>
        <h5 class="pull-left bold">{{ lang[this.appLang].add_tag }}</h5>
        <br />
        <p class="pull-left">{{ lang[this.appLang].add_tag_p }}</p>
      </div>

      <hr
        class="
          decoration decoration-lines-thin
          color-black
          small-bottom
          half-top
        "
      />

      <p class="center-text font-13 half-top half-bottom">
        <span v-html="lang[this.appLang].completing_profile"></span>
        <a
          :href="
            'mailto:support@tapni.co?subject=' +
            lang[this.appLang].completing_profile_subject
          "
          class="pointer color-black"
        >
          support@tapni.co</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../store/event-bus";
export default {
  name: "ProfileSetup",
  data() {
    return {
      toggle: false,
    };
  },
  computed: {
    profileLink() {
      return `${this.$store.getters.subdomain}.tapni.co/${this.user.username}`;
    },
    addedPhoto() {
      return this.user.photo !== "https://cdn.tapni.co/images/user.png";
    },
    addedTag() {
      return this.user && this.user.tags && this.user.tags.length > 0;
    },
    addedLink() {
      return this.user && this.user.links && this.user.links.length > 0;
    },
    verifiedEmail() {
      return this.user && this.user.status === "VERIFIED";
    },
  },
  mounted() {
    EventBus.$on("toggleProfileSetupModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
  },
  methods: {
    addPhoto() {
      this.close();
      EventBus.$emit("toggleUploadModal", "photo");
    },
    addTag() {
      this.close();
      this.$router.push("/tags#activate");
    },
    addLink() {
      this.close();
      this.$router.push("/link/add");
    },
    verifyEmail() {
      if (!this.verifiedEmail) EventBus.$emit("toggleAddAccountModalVerify");
    },
    close() {
      EventBus.$emit("closeModal");
    },
    toggleModal() {
      this.toggle = !this.toggle;
    },
  },
};
</script>

<style scoped>
.progress-img {
  margin: 0 auto;
  margin-top: -10px;
  width: 55px;
}
.no-border {
  border: solid 1px #e8e8e8 !important;
}
.border-black {
  border: 1px solid black;
}
</style>
