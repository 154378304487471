<template>
  <div
    class="menu-wrapper menu-white menu-modal activate-page center-text"
    :class="{ 'active-menu': toggle }"
    style="height: auto; max-height: 90vh; margin-top: -172.5px"
  >
    <span>
      <a
        @click="close"
        class="color-black pull-right pointer"
        style="margin-top: 5px; position: absolute; right: 5px"
      >
        <i class="font-17 fa color-black">
          <img
            src="https://cdn.tapni.co/icons/down-arrow.png"
            class="fa responsive-image"
            style="width: 45%"
          />
        </i>
      </a>
      <!--
      <img
        v-if="!(webShareApiSupported || isNative)"
        :src="'https://cdn.tapni.co/icons/qr2.png'"
        class="qr-img small-bottom"
        alt="QR"
      />
      -->
      <h1 class="bold center-text small-top full-bottom">
        {{ lang[this.appLang].share_profile }}
      </h1>

      <div v-if="vcardExist">
        <span @click='changeQRType("profileShare")' class='black-text pointer'  :class='{ "underline": qrType=="profileShare" }' style="margin-right:25px; font-weight: 500;">Share Profile</span>
        <span @click='changeQRType("contactCard")'  class='black-text pointer'  :class='{ "underline": qrType=="contactCard" }' style="margin-left:25px;; font-weight: 500;">Contact Card</span>
      </div>
      <div id="qr-code" class="center-text small-top small-bottom"></div>

      <button @click="copy('https://' + profileLink)" class="button white-button font-12 pointer small-top" style="width: 100%;"> <div style="display: flex; align-items: center;"><img src="https://cdn.tapni.co/icons/copy.png" style="width: 30px; height: 30px; margin-right: 10px;"> <span style="width: 75%; font-size: 14px;">{{'https://' + profileLink}} </span> </div></button>
      <button v-if="webShareApiSupported || isNative"  @click="() => share('https://' + profileLink, 'qr')" class="button white-button font-12 pointer small-top" style="width: 100%;"> <div style="display: flex; align-items: center;"><img src="https://cdn.tapni.co/icons/share.png" style="width: 30px; height: 30px; margin-right: 10px;"> <span style="width: 75%; font-size: 16px;">{{lang[this.appLang].share_profile}}</span> </div></button>
      <button v-if="isiOS" @click="saveToAppleWallet"class="button white-button font-12 pointer small-top" style="width: 100%;"> <div style="display: flex; align-items: center;"><img src="https://cdn.tapni.co/icons/apple-wallet.png" style="width: 30px; height: 30px; margin-right: 10px;"> <span style="width: 75%; font-size: 16px;">{{lang[this.appLang].save_to_wallet}}</span> </div></button>


      <!--
      <LinkIcon
        @click.native="copy('https://' + profileLink)"
        :link-style="'link-list'"
        :data="{ type: 'copy', text: profileLink }"
        hideExternalIcon
        :editing="false"
      />
      <LinkIcon
        v-if="webShareApiSupported || isNative"
        @click.native="() => share('https://' + profileLink, 'qr')"
        :link-style="'link-list'"
        :data="{ type: 'share', text: lang[this.appLang].share_profile }"
        hideExternalIcon
        :editing="false"
      />
      <LinkIcon
        v-if="isiOS"
        @click.native="saveToAppleWallet"
        :link-style="'link-list'"
        :data="{
          type: 'apple-wallet',
          text: lang[this.appLang].save_to_wallet,
        }"
        hideExternalIcon
        :editing="false"
      />
      -->
      <p
        @click="toggleUsernameModal()"
        class="
          color-black
          center-text
          pointer
          half-top
          half-bottom
          tiny-top-padding
          font-13
        "
        v-html="lang[this.appLang].share_profile_change_username"
      />
    </span>
  </div>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import { mapActions } from "vuex";
import QRCodeStyling from "qr-code-styling";
import LinkIcon from "../LinkIcon";
import UtilService from '@/services/UtilService';

export default {
  name: "QR",
  components: { LinkIcon },
  data() {
    return {
      toggle: false,
      rendered: false,
      qrType: 'profileShare',
      vcardExist: false
    };
  },
  computed: {
    profileLink() {
      return this.user && this.user.username
        ? this.$store.getters.subdomain + ".tapni.co/" + this.user.username
        : "tapni.co";
    },
    webShareApiSupported() {
      return navigator.share;
    },
  },
  mounted() {
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
    EventBus.$on("toggleModal", this.toggleModal);

    if (!this.isEmpty(this.user)) {
      this.init();
    }
  },
  methods: {
    ...mapActions(["eventLog"]),
    init() {
      if (!this.isEmpty(this.user) && !this.rendered) {
        this.rendered = true;
        let qrData = "https://" + this.profileLink + "?utm_source=tapni-app&utm_medium=qr"
        this.generateQRCode(qrData, 260)
        if(Object.keys(this.user.vcard).length > 0) this.vcardExist = true
      }
    },
    generateQRCode(data, qrSize){

      const qrCode = new QRCodeStyling({
        width: qrSize,
        height: qrSize,
        margin: 0,
        data,
        dotsOptions: {
          color: "#000000",
          type: "extra-rounded",
        },
        backgroundOptions: {
          color: "#efefef00",
        },
        imageOptions: {
          crossOrigin: "anonymous",
          imageSize: 0.5,
        },
        cornersSquareOptions: {
          type: "extra-rounded",
        },
      });

      let element = document.getElementsByTagName("canvas"),
          index;

      for (index = element.length - 1; index >= 0; index--) {
        element[index].parentNode.removeChild(element[index]);
      }

      qrCode.append(document.getElementById("qr-code"));
    },
    close() {
      EventBus.$emit("toggleModal");
    },
    toggleModal() {
      this.toggle = !this.toggle;

      if (this.toggle) {
        this.eventLog({
          type: "qr_modal",
        });
      }
    },
    changeQRType(type) {
      if(type === 'profileShare') {
        this.qrType = 'profileShare'
        let qrData = "https://" + this.profileLink + "?utm_source=tapni-app&utm_medium=qr"
        this.generateQRCode(qrData, 260)
      }
      if(type === 'contactCard') {
        let vcardString = UtilService.generateVcard(this.user.vcard)
        this.qrType = 'contactCard'
        this.generateQRCode(vcardString, 300)
      }
    },
    toggleUsernameModal() {
      EventBus.$emit("toggleUsernameModal");
      this.toggleModal();
    },
    saveToAppleWallet() {
      location.href =
        process.env.VUE_APP_API_ROOT + "/v1/users/" + this.user.id + "/passkit";
    },
  },
  watch: {
    user() {
      this.init();
    },
    "user.username"() {
      this.rendered = false;
      this.init();
    },
  },
};
</script>

<style scoped>
.link-copy:hover {
  color: #2d2d2d;
}
.qr-img {
  margin: 0 auto;
  margin-top: 2px;
  width: 65px;
}

</style>
